import React from 'react';

import Box from '@components/Box/Box';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import ApplicationListing from '@components/Listing/ApplicationListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const ApplicationsPage = () => {
	return (
		<Page>
			<SEO
				title="Applications | UHV Design"
				description="Our products can be found in everything from the simplest of R&D experiments to the world's best surface science instruments. 25 years of experience. Learn more"
			/>

			<PageSection padding="sm">
				<MotionH
					level={2}
					as="h1"
					color="blue"
					className="hide-desktop"
				>
					Applications
				</MotionH>

				<ApplicationListing />
			</PageSection>

			<PageSection>
				<MotionH level={2} as="h1" className="hide-mobile">
					Applications
				</MotionH>

				<Columns direction="reverse" gap="lg">
					<div>
						<Box>
							<Content spacing="sm">
								<H level={3} as="h2">
									Key Facts
								</H>

								<ul>
									<li>
										Over 25 years of experience working with
										customers across a wide range of
										applications
									</li>

									<li>
										Products designed to address application
										specific challenges
									</li>
								</ul>
							</Content>
						</Box>
					</div>

					<Content>
						<MotionCopy>
							Our products are used in a diverse range of
							applications from simple R&amp;D experiments through
							to providing highly precise and reliable sample
							manipulation in some of the world's best surface
							science instruments and deposition tools.
						</MotionCopy>

						<MotionCopy>
							Use the links above to learn more about where
							products are used and why they are selected.
						</MotionCopy>
					</Content>
				</Columns>
			</PageSection>
		</Page>
	);
};

export default ApplicationsPage;
